<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group class="mr-1 mb-md-0">
            <CreateModal @submit="upsertPromotion" />
          </b-form-group>
          <b-form-group label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table-simple striped hover responsive class="position-relative items-center" :per-page="perPage"
        :current-page="currentPage">
        <b-thead>
          <b-tr>
            <b-th>โปรโมชั่น</b-th>
            <b-th class="d-none d-md-table-cell">รายละเอียด</b-th>
            <b-th>เปิด/ปิด</b-th>
            <b-th>Actions</b-th>
          </b-tr>
        </b-thead>

        <draggable tag="tbody" v-model="items" @start="drag = true" @end="dragend">
          <b-tr v-for="item in items" :key="item.id">
            <b-td class="text-truncate">{{ item.name }}</b-td>
            <b-td class="d-none d-md-table-cell">{{ item.description }}</b-td>
            <b-td>
              <div class="text-nowrap">
                <b-form-checkbox v-model="item.status" :value="1" :unchecked-value="0"
                  class="custom-control-primary" name="check-button" switch
                  @change="switchEnable(item.id, item.status)" />
              </div>
            </b-td>
            <b-td>
              <b-dropdown id="dropdown-3" text="Success" size="sm" variant="success">
                <template #button-content>
                  <i class="far fa-edit mr-50" /> จัดการ
                </template>
                <b-dropdown-item @click="edit(item)"><i class="fas fa-pencil mr-1 text-info" /> แก้ไข
                </b-dropdown-item>
                <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </draggable>
      </b-table-simple>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <EditModal @submit="upsertPromotion" :editModal="editModal" :form="form" @setModal="editModal = $event" />
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BIconController,BTableSimple, BThead, BTbody, BTr, BTh, BTd, BOverlay, BDropdownItem, BDropdown, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CreateModal from './create-modal.vue'
import EditModal from './edit-modal.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    BIconController,BTableSimple, BThead, BTbody, BTr, BTh, BTd, BOverlay, BDropdownItem, BDropdown, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckbox,
    CreateModal,
    EditModal,
    draggable
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      checked: false,
      depositdata: [],
      DataEditID: '',
      name: '',
      detail: '',
      editModal: false,
      turn: '',
      optionsMember: '',
      isLoading: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      form: {},
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'name', label: 'โปรโมชั่น' },
        { key: 'description', label: 'รายละเอียด' },
        // { key: 'turn', label: 'เทิร์น' },

        { key: 'enable', label: 'เปิด/ปิด' },
        { key: 'actions', label: 'Actions' },
        // { key: 'actions', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    // this.getUserslist()
    this.getPromotions()
  },
  methods: {
    async dragend() {
      this.isLoading = true;
      try {
        await this.$http.post('/promotion/drag', { data: this.items });

        this.getPromotions();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    switchEnable(id, data) {
      const obj = {
        id,
        status: data,
      }
      // console.log(obj)
      this.$http
        .post('/promotion/status', obj)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    async getPromotions() {
      this.isLoading = true;

      try {
        const { data } = await this.$http
          .get('/promotion/index/v2')
        this.onFiltered(data)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    onFiltered(filteredItems) {
      filteredItems.forEach(item => {
        if (item.agent_use) {
          const x = item.agent_use.split(',')
          const check = x.indexOf(this.UserData.agent_id.toString())
          // console.log(check)
          if (check >= 0) {
            // eslint-disable-next-line no-param-reassign
            item.status = true
          } else {
            // eslint-disable-next-line no-param-reassign
            item.status = false
          }
        }
      })
      // for (let i = 0; i < filteredItems.length; i += 1) {
      //   if (filteredItems[i].agent_use) {
      //     const x = filteredItems[i].agent_use.split(',')
      //     const check = x.indexOf(this.UserData.agent_id.toString())
      //     if (check >= 0) {
      //     // eslint-disable-next-line no-param-reassign
      //       filteredItems[i].status = true
      //     } else {
      //     // eslint-disable-next-line no-param-reassign
      //       filteredItems[i].status = false
      //     }
      //   }
      // }
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getAdmin(addby, index) {
      this.$http
        .get(`/admin/show/${addby}`)
        .then(response => {
          console.log(response.data.name)
          this.depositdata[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    upsertPromotion(form) {
      this.isLoading = true;
      try {
        const { data } = this.$http.post('/promotion/upsert', form);

        this.getPromotions();
        // console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/promotion/delete/${id}`)
            .then(response => {
              this.getPromotions()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('Cancelled', 'not been deleted !', 'error')
        }
      })
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.name = ''
      this.detail = ''
      this.turn = ''
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    edit(form) {
      console.log(form.canplay);
      if (form.canplay) {
        form.cp = form.canplay.split(',')
      }
      this.form = form;
      this.editModal = true;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
